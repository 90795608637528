import React from 'react'
import { Box } from '@mui/material'
import styled from '@mui/material/styles/styled'

export const FlexFlowWrap = styled(Box)`
    flex-flow: wrap;
`

export const Sticky = styled(Box)`
    @media screen and (min-width: 768px) {
        position: sticky;
        top: 4rem;
    }
`

export const StickyWithTop = styled(Box)`
    @media screen and (min-width: 768px) {
        position: sticky;
        top: 4rem;
    }
    margin-top: 1rem;
`

export const PostWH = styled(Box) `
        height: auto;
        width: 100%;
        margin-bottom: 1rem;
`

export const Padding = styled(Box) `
        padding-top: 1rem;
`

export const MarginTop = styled(Box) `
        margin-top: 1rem;
`

export const HeaderContainer = styled(Box) `
        position: sticky;
        top: 0px;
        fliter: blur(6px);
        backdrop-filter: blur(6px);
        padding: 12px;
        z-index: 1000;
        background: var(--more-light-bg);
        border-bottom: var(--more-light-shadow);
`

export const HeaderLeft = styled(Box) `
        justify-content: flex-start;
        width: fit-content;
        margin-right: auto;
`

export const HeaderRight = styled(Box) `
        justify-content: flex-end;
        width: fit-content;
`

export const Foot = styled((props: any) => <footer {...props} />)`
        display: block;
        padding: 3rem 1.5rem 3rem;
`

export const FlexBlockContainer = styled(Box) `
        display: block;
        padding: var(--container-padding);
        flex-grow: 1;
        margin: 0 auto;
        max-width: var(--container);
    `
export const FlexCols = styled(Box) `
        display: var(--resp-flex);
        margin: 0 -.75rem;
    `
export const FlexMain = styled((props) => <main {...props}/>) `
        display: block;
        order: 2;
        width: var(--col-main);
        box-sizing: border-box;
        flex: none;
        padding: 0 0.75rem;
    `
export const FlexLeft = styled((props) => <aside {...props} />) `
        display: block;
        order: 1;
        width: var(--col-side);
        box-sizing: border-box;
        flex: none;
        padding: 0 0.75rem;
    `
export const FlexRight = styled((props) => <aside {...props} />) `
        display: var(--hide-desktop);
        order: 3;
        width: var(--col-side);
        box-sizing: border-box;
        flex: none;
        padding: 0 0.75rem;
    `

export const ShowDesktop = styled(Box) `
        display: var(--show-desktop);
`

export const noPaddingAndMargin = {
    margin: 0, padding: 0
}

export const linkedStyle = {
    textDecoration: 'none',
    color: '#ffe69b'
}

export const LeftHolder = styled((props) => <aside {...props} />)`
    display: block;
    order: 1;
    width: var(--col-side);
    box-sizing: border-box;
    padding: 0 0.75rem;
    minWidth: 0;
    height: 0;
    maxHeight: 0;
`

export const noLinkStyle = { style: { color: 'inherit', textDecoration: 'auto' }, activeStyle:{ color: 'inherit', textDecoration: 'auto' } }

export const noLinkStyleTag = { style: { color: 'inherit', textDecoration: 'auto' } }

export const bluredBackgroundAppbarStyle = {
    position: 'sticky',
    top: 0,
    fliter: 'blur(8px)',
    backdropFilter: 'blur(8px)',
    zIndex: 1000,
    background: 'rgba(0,0,0,0.75)',
    borderBottom: 'var(--more-light-shadow)'
}

export const blurCardBackgroundStyle = {
    background: 'rgba(0,0,0,0.2)',
    backdropFilter: 'blur(16px)',
    fliter: 'blur(16px)',
    borderRadius: '8px',
    padding: '1rem',
    marginBottom: '1rem'
}

